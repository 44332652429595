import { html } from "lit";
import { RoutePage } from "../../shared/route-page";

export class PagePureManifestoPE extends RoutePage {
  renderTitle() {
    return html`${super.renderTitle()}: 2. Progressive Enhancement`;
  }

  renderContent() {
    return html`
      <blockquote>
        Use Progressive Enhancement
        <br />...before even thinking about web components
      </blockquote>

      <p>
        Start with a basic HTML structure, ensuring the core content and
        functionality are accessible to all users.
      </p>
      <p>
        Progressive Enhancement prioritizes HTML because it is the most resilient layer; if
        CSS or JavaScript fails, the HTML will still render correctly.
      </p>

      <blockquote>
        Progressive Enhancement is a lost art that, with the advent of web
        frameworks that have declared <br/>
        <code>&lt;div id="app"&gt;&lt;/div&gt;</code> 
        <br/> as the summum of HTML
        building, has been dismissed as an artefact of the past.
      </blockquote>



      <h3>Key benefits</h3>

      <ul class="simple-list">
        <li>
          Resilience: Ensures basic functionality works even if parts of the
          stack fail.
        </li>
        <li>
          Accessibility: Encourages best practices like semantic markup,
          improving accessibility.
        </li>
        <li>
          Device and Connectivity Support: Helps users with limited devices or
          connectivity.
        </li>
      </ul>

      <h3>Implementation Steps</h3>

      <ul class="simple-list">
        <li>Start with HTML: Ensure basic functionality using only HTML.</li>
        <li>
          Add Interactive Elements: Use elements like dynamic autocomplete with
          fallbacks to maintain core functionality.
        </li>
        <li>
          Enhance with CSS and JavaScript: Add styling, interactive features,
          and smoother interactions.
        </li>
      </ul>

      <h3>Building Complex Services</h3>

      <ul class="simple-list">
        <li>
          Break down services into interactions to determine if JavaScript is
          necessary.
        </li>
        <li>
          Ensure JavaScript implementations have fallbacks for accessibility.
        </li>
        <li>
          Consider non-digital channels for users who cannot access digital
          services.
        </li>
      </ul>
      <p>
        Using JavaScript frameworks can be convenient but may cause performance
        issues, so weigh the benefits carefully.
      </p>

      <call-out icon="code" title="Material-Style Input">
        <code-example id="/material-input"></code-example>
      </call-out>

      
      <call-out icon="code" title="Semantic Dropdown Button">
        <code-example id="/dropdown-button"></code-example>
      </call-out>

      
    `;
  }
}
