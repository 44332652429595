import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { RoutePage } from "../shared/route-page";

export class PageBlog extends RoutePage {
  static get properties() {
    return {
      list: {
        type: Array,
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.allBlogs = this.getBlogs();
  }

  getBlogs() {
    return app.cms
      .list("blog")
      .map((blog) => {
        return {
          ...blog,
          publishedDate: new Date(blog.published),
        };
      })
      .sort((a, b) => {
        if (a.publishedDate > b.publishedDate) return -1;
        else if (b.publishedDate > a.publishedDate) return 1;
        return 0;
      });
  }

  getBlogTags() {
    const tagMap = new Map();
    this.allBlogs.forEach((i) => {
      for (const tag of i.tags ?? []) {
        let tagRefs = [];
        if (tagMap.has(tag)) tagRefs = tagMap.get(tag);
        else tagMap.set(tag, tagRefs);
        tagRefs.push(i);
      }
    });

    const tags = [];
    for (const [key, value] of tagMap) {
      tags.push({
        name: key,
        weight: value.length,
      });
    }
    return tags;
  }

  renderContent() {
    this.blogId = location.pathname.substring(5);

    if (this.blogId) {
      const blog = app.cms.data.blog[this.blogId];
      return this.renderSingleChild(blog, false);
    }

    return this.renderCloud();
  }

  getChildren() {
    if (this.list) {
      return this.list;
    }

    return this.allBlogs;
  }

  renderChildren() {
    if (this.blogId) return;

    return html`
      <p>
        We're trying to get you the most interesting blogs about sticking to the
        Web Standards.
      </p>

      <nav class="blog-list">
        ${repeat(this.getChildren(), (item) =>
          this.renderSingleChild(item, true)
        )}
      </nav>
    `;
  }

  renderSingleChild(blog, isList) {
    blog.publishedDate = new Date(blog.published);

    return html` <article
      id="${blog.id}"
      itemscope
      itemtype="http://schema.org/BlogPosting"
    >
      ${this.renderBlogTitle(blog, isList)}

      <figure>
        <img alt="${blog.alt}" src="${blog.img}" />
        <figcaption>
          ${blog.alt ? blog.alt + " - " : ""} ${new URL(blog.url).host}
        </figcaption>
      </figure>

      <small itemprop="description">${blog.description}</small>

      ${isList
        ? ""
        : html`<a
            rel="noopener"
            class="v-space"
            target="_blank"
            href="${blog.url}"
            ><svg-icon icon="link"></svg-icon> ${blog.url}</a
          >`}

      <span class="pub-info">
        <address
          class="author"
          itemprop="author"
          itemscope
          itemtype="http://schema.org/Person"
        >
          ${this.renderAuthorDetails(blog, isList)}
        </address>
      </span>

      ${this.renderVideo(blog, isList)}
    </article>`;
  }

  renderVideo(blog, isList) {
    if (isList || !blog.youtube) return;

    return html` <embed-yt code="${blog.youtube}"></embed-yt> `;
  }

  renderAuthorDetails(blog, isList) {
    const time = html`<time pubdate datetime="${blog.publishedDate}">${blog.published}</time>`;

    if (!blog.handle) return html`${blog.handle_id ?? "unknown"}, ${time}`;

    if (!blog.handle.permaLink) return html`${blog.handle?.name}, ${time}`;

    if (isList) return html`${blog.handle?.name}, ${time}`;

    return html`
      <div class="v-space">
        <h4>
          Written by ${blog.handle.name}, ${time}
          
        </h4>
        <fan-image size="128" handle="${blog.handle.id}"></fan-image>
      </div>
    `;
  }

  renderBlogTitle(blog, isList) {
    if (isList) {
      return html`<a href="/blog${blog.id}" itemprop="url">
        <h3 class="ellipsis" itemprop="headline">${blog.title}</h3>
      </a>`;
    } else {
      return html`<h3 itemprop="headline">${blog.title}</h3>`;
    }
  }

  renderCloud() {
    return html`<nav id="tag-cloud">
      <word-cloud
        background-color="#666"
        max="30"
        @selectword=${this.tagClick}
        .words=${this.getBlogTags()}
      ></word-cloud>
    </nav>`;
  }

  tagClick(e) {
    e.preventDefault();
    e.stopPropagation();
    
    const docs = app.cms.findByTags([e.detail], {
      sources: ["blog"],
    });
    this.list = docs.map((result) => {
      return {
        ...result.item,
        publishedDate: new Date(...result.item.published),
      };
    });
  }
}
