import { LitElement, css, html } from "lit";

export class CallOut extends LitElement {
  static get properties() {
    return {
      title: {
        type: String,
      },
      icon: {
        type: String,
      },
    };
  }

  static styles = css`
    :host {
      display: inline-block;
      --icon-size: 36px;
    }

    [data-callout] {
      border-radius: 1rem;
    }

    :host(:not([icon="code"])) {
      [data-callout] {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    :host([icon="code"]) {
      [data-callout] {
        //background-color: #222;
        padding: 1rem;
      }
    }

    [data-callout] header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      gap: 1rem;

      border-radius: 1rem;

      > * {
        margin: 0;
      }

      h2 {
        font-size: calc(var(--icon-size) * 0.6);
      }
    }

    main {
      margin-top: 0.5rem;
    }
  `;

  render() {
    return html`
      <section data-callout>
        <header>
          <svg-icon icon="${this.icon}"></svg-icon>
          <h2>${this.title}</h2>
        </header>
        <main>
          <slot></slot>
        </main>
      </section>
    `;
  }
}

customElements.define("call-out", CallOut);
