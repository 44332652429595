import { html } from "lit";
import { ComponentPage } from "./component-page";

export class PageRouting extends ComponentPage {

  renderContent() {
    return html`
      <p>
        Search for 'SPA Routing' in Google (or in your favorite AI) and you'll
        find tons of results.
      </p>

      <p>
        Frameworks come with their preferred router, but once you've decided to
        not use a framework, you need to decide for yourself what to use.
      </p>

      <blockquote>
        I've asked AI to sum up what features a good router should support 😉
      </blockquote>

      <ul class="sumup fly-in accordion">
        <li>
          <details>
            <summary>🌟 Dynamic Route Matching</summary>
            <div>
              <p>
                Ability to match routes dynamically, including support for
                parameters (e.g., /user/:id).
              </p>
            </div>
          </details>
        </li>

        <li>
          <details>
            <summary>🔺 Nested Routes</summary>
            <div>
              <p>Support for nested routes to allow complex UI structures.</p>
            </div>
          </details>
        </li>

        <li>
          <details>
            <summary>🤚 Route Guards</summary>
            <div>
              <p>
                Mechanisms to protect certain routes, such as authentication
                checks before allowing access.
              </p>
            </div>
          </details>
        </li>

        <li>
          <details>
            <summary>⌛ History Management</summary>
            <div>
              <p>
                Proper handling of browser history, including support for
                pushState and hash-based routing.
              </p>
            </div>
          </details>
        </li>

        <li>
          <details>
            <summary>🔍 404 Handling</summary>
            <div>
              <p>
                Customizable handling for unknown routes (e.g., displaying a 404
                page).
              </p>
            </div>
          </details>
        </li>

        <li>
          <details>
            <summary>👉🏼 Redirection</summary>
            <div>
              <p>Support for redirecting from one route to another.</p>
            </div>
          </details>
        </li>
        <li>
          <details>
            <summary>📜 Scroll Behavior</summary>
            <div>
              <p>
                Control over scroll behavior when navigating between routes.
              </p>
            </div>
          </details>
        </li>

        <li>
          <details>
            <summary>❔Query Parameters</summary>
            <div>
              <p>Handling of query parameters in routes</p>
            </div>
          </details>
        </li>
      </ul>

      <p>
        And then, some might claim that <em>Lazy Loading</em>,
        <em>Transition Effects</em> and integration with
        <em>State Management</em> would be features a good router should
        support.
      </p>

      <p>
        Of course, I strongly disagree with this, since these are entirely
        different features that have nothing to do with routing itself (but can
        perfectly be handled in vanilla JavaScript).
      </p>

      <h3>Native Routing</h3>

      <p>
        Without a framework, would you be lost if you had to facilitate all this
        funcionality?
      </p>

      <p>
        Luckily, Browser APIs and ES6 have evolved to a state where much of how
        we used to have to build these features has been modernized drastically.
      </p>

      <p>
        For instance, where you previously had to work with
        <code>history.pushState()</code> and <code>window.popState()</code>,
        which is pretty low-level stuff for you, as an app developer, to be
        working with. This required a lot of boilerplate code to handle
        navigation and UI updates.
      </p>

      <p>
        The modern approach would be to use the new
        <code>navigation.navigate</code> event, and use
        <code>event.intercept</code>
        for custom handling (read 'routing'). This simplifies navigation by
        centralizing event handling.
      </p>

      <p>
        An example of this routing approach can be found in the pure-web NPM
        package
      </p>
    `;
  }
}
