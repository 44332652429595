import { PageHome } from "./pages/home";
import { PageContact } from "./pages/contact";
import { PageBenefits } from "./pages/benefits";
import { PageProfile } from "./pages/profile";
import { PageBlog } from "./pages/blog";
import { PageShowCases } from "./pages/showcases/index";
import { PageSources } from "./pages/sources";
import { PageNarrative } from "./pages/leading-narrative";
import { PageStateManagement } from "./pages/pure-components/state-management";
import { PagePureWebApps } from "./pages/pure-components/index";
import { PageRouting } from "./pages/pure-components/routing";
import { PageExecutiveSummary } from "./pages/executive-summary";
import { PageEITD } from "./pages/ei-tech-debt";
import { PageBackToVanilla } from "./pages/back-to-vanilla";
import { PageShowCaseQogni } from "./pages/showcases/qogni";
import { PagePureManifesto } from "./pages/pure-manifesto/index";
import { PagePureManifestoHtml } from "./pages/pure-manifesto/html";
import { PagePureManifestoPE } from "./pages/pure-manifesto/progressive-enhancement";
import { PagePureManifestoLD } from "./pages/pure-manifesto/light-dom";
import { PagePureManifestoCE } from "./pages/pure-manifesto/custom-elements";
import { PagePureManifestoUSD } from "./pages/pure-manifesto/understand-shadow-dom";
import { PageUIComponents } from "./pages/pure-components/ui-components";
import { PagePWA } from "./pages/pwa";
import { PagePWFCase } from "./pages/showcases/pwf-case";
import { PageTesting } from "./pages/pure-components/testing";
import { PageDataBinding } from "./pages/pure-components/data-binding";
import { PageTemplating } from "./pages/pure-components/templating";
import { PageFormHandling } from "./pages/pure-components/form-handling";
import { PageBuildTransBundle } from "./pages/trans-build-bundle";

export const config = {
  org: {
    domain: "pureweb.dev",
    email: "info@pureweb.dev"
  },
  routes: {
    "/": {
      name: "Home",
      run: PageHome,
      config: {
        icon: "pure-p",
        title: "",
        description: "Pure Web Foundation Homepage",
      },
    },
    "/leading-narrative": {
      run: PageNarrative,
      config: {
        title: "The Leading Narrative (2014-now)",
      },
    },
    "/executive-summary": {
      run: PageExecutiveSummary,
      config: {
        title: "Executive Summary",
        icon: "info",
        persona: "dec",
      },
    },
    "/benefits": {
      run: PageBenefits,
      config: {
        title: "The Benefits of Sticking to the Standards",
      },
    },
    "/manifesto": {
      run: PagePureManifesto,
      config: {
        icon: "document",
        title: "Pure Web Manifesto",
        persona: "dev",
      },
      routes: {
        "/html": {
          run: PagePureManifestoHtml,
          config: {
            icon: "html",
          },
        },
        "/progressive-enhancement": {
          run: PagePureManifestoPE,
          config: {
            icon: "progress",
            tags: ["progressive-enhancement"],
            shortName: "Progressive Enhancement"
          },
        },
        "/light-dom": {
          run: PagePureManifestoLD,
          config: {
            icon: "bulb",
            tags: ["light-dom"],
            shortName: "Light DOM"
          },
        },
        "/custom-elements": {
          run: PagePureManifestoCE,
          config: {
            icon: "add-custom",
            tags: ["custom-elements"],
            shortName: "Custom Elements"
          },
        },
        "/understand-shadow-dom": {
          run: PagePureManifestoUSD,
          config: {
            icon: "dark",
            title: "Understand Shadow DOM",
            shortName: "Shadow DOM",
            tags: ["shadow-dom", "light-dom", "web-components"],
          },
        },
      },
    },
    "/pure-components": {
      run: PagePureWebApps,
      config: {
        icon: "app",
        persona: "dev",
        title: "App Components",
        shortName: "Pure Components",
        tags: ["routing", "state management", "ui-components", "building-blocks", "spa"]
      },
      routes: {
        "/routing": {
          run: PageRouting,
          config: {
            icon: "routes",
            title: "Modern Routing in Pure JavaScript",
            shortName: "Routing",
          },
        },
        "/state-management": {
          run: PageStateManagement,
          config: {
            icon: "state",
            tags: ["state-management", "proxy", "lit-element"],
            shortName: "State Management"
          },
        },
        "/ui-components": {
          run: PageUIComponents,
          config: {
            icon: "lego",
            tags: ["web-components"],
            shortName: "UI Components"
          },
        },
        "/testing": {
          run: PageTesting,
          config: {
            icon: "test",
            shortName: "Testing"
          }
        },
        "/data-binding": {
          run: PageDataBinding,
          config: {
            icon: "data",
            shortName: "Databinding",
            tags: ["proxy", "lit-element"]
          }
        },
        "/page-templating": {
          run: PageTemplating,
          config: {
            icon: "template",
            shortName: "Templating"
          }
        },
        "/form-handling": {
          run: PageFormHandling,
          config: {
            icon: "form",
            tags: ["ui-components", "forms", "accessibility", "api"],
            shortName: "Form Handling"
          }
        }
      },
    },

    "/building-transpiling-bundling": {
      run: PageBuildTransBundle,
      config: {
        icon: "build",
        shortName: "Building",
        title: "Building, Transpiling & Bundling",
        tags: ["nobuild", "bundling", "transpiling", "building"]
      }
    },

    "/back-to-vanilla": {
      run: PageBackToVanilla,
      config: {
        icon: "clean",
        persona: "dev",
        tags: ["web-components", "vanilla"],
        shortName: "Back 2 Vanilla"
      },
    },

    "/externally-inflicted-tech-debt": {
      run: PageEITD,
      config: {
        icon: "bomb",
        persona: "all",
        tags: ["tech-debt", "frameworks", "vue", "react", "angular"]
      },
    },

    "/contact": {
      run: PageContact,
      config: {
        title: "Get in Contact",
      },
    },
    "/profile": {
      run: PageProfile,
      hidden: true,
      config: {
        icon: "love",
        title: "Our Fans, Contributors and Partners",
        shortName: "Supporters",
      },
      routes: {
        "/*": {},
      },
    },
    "/showcases": {
      run: PageShowCases,
      config: {
        icon: "bulb",
      },
      routes: {
        "/qogni": {
          run: PageShowCaseQogni,
          config: {
            icon: "qogni",
            tags: ["pwa", "lit-element", "web-components", "pure-manifesto"],
            shortName: "Qogni App",
          }
        },
        "/pwf":{
          run: PagePWFCase,
          config: {
            icon: "pure-p",
            tags: ["web-standards", "lit-element", "routing", "pure-manifesto"],
            shortName: "PWF"
          }
        }
      },
    },
    "/sources": {
      run: PageSources,
      routes: {
        "/*": {},
      },
      config: {
        icon: "src",
        persona: "dev",
        tags: ["sourcecode"]
      },
    },
    "/blog": {
      run: PageBlog,
      config: {
        icon: "menu",
        title: "The Pure Blog",
      },
      routes: {
        "/*": {},
      },
    },
    "/pwa": {
      run: PagePWA,
      config: {
        icon: "pwa",
        title: "PWA",
        tags: ["pwa"],

      },
    }
    
  },
};
