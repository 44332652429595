import { html } from "lit";
import { RoutePage } from "../shared/route-page";

export class PageNarrative extends RoutePage {
  renderContent() {
    return html`
      <blockquote>
        Despite a rising trend towards using web standards and browser APIs
        directly, the dominant narrative over the past decade has emphasized the
        efficiency and scalability of frameworks like React, Vue, and Angular.
      </blockquote>

      <figure>
        <img
          src="/assets/img/vanilla-stats.svg"
          alt="Going vanilla, from 2014 to 2024"
        />
        <figcaption>The decline of working only with the standards</figcaption>
      </figure>

      <p>Here are some key points that are part of this narrative:</p>

      <ul class="accordion">
        <li>
          <details>
            <summary>💯 Popularity and Adoption</summary>
            <p>
              Articles and comparisons frequently highlight the widespread
              adoption of these frameworks. For instance, a detailed comparison
              on FreeCodeCamp<sup><a href="#footnotes">1</a></sup
              >. discusses how frameworks like React, Angular, and Vue have
              become indispensable in web development due to their structured
              approaches and pre-defined components.
            </p>
          </details>
        </li>

        <li>
          <details>
            <summary>👯 Community and Ecosystem</summary>
            <p>
              The extensive support, tutorials, and documentation available for
              these frameworks have made them the go-to choices for many
              developers. This is often cited as a reason for their popularity.
            </p>
          </details>
        </li>
        <li>
          <details>
            <summary>💥 Performance and Features</summary>
            <p>
              Comparisons often focus on the performance benefits and features
              of these frameworks, such as component-based architecture, state
              management, and ease of integration.
            </p>
          </details>
        </li>
        <li>
          <details>
            <summary>🎖️ Learning Resources and tutorials</summary>
            <p>
              Many learning resources and tutorials emphasize starting with
              these frameworks to build web applications, often suggesting that
              understanding these tools is crucial for modern web development.
            </p>
          </details>
        </li>
        <li>
          <details>
            <summary>📈 Industry Trends</summary>
            <p>
              Industry trends and job market demands also reflect this
              narrative. Many job postings project requirements list proficiency
              in these frameworks as essential skills for frontend developers.
            </p>
          </details>
        </li>
      </ul>

      <blockquote>
        It's a David against Goliath fight, but we have to get started.
      </blockquote>

      <h3>
        The React era <a href="#footnotes"><sup>2</sup></a>
      </h3>

      <p>
        During the last decade, React has become the industry standard for
        frontend development.
      </p>

      <figure>
        <img
          src="/assets/img/angular-react-vue.webp"
          alt="Number of apps built with the major frameworks"
        />
        <figcaption>
          Number of apps built using the major frameworks - source:
          https://www.zartis.com/ (2022)
        </figcaption>
      </figure>

      <nav id="footnotes">
        <ol class="simple-list">
          <li>
            <a
              target="_blank"
              rel="noopener"
              href="https://www.freecodecamp.org/news/front-end-javascript-development-react-angular-vue-compared/"
              >FreeCodeCamp: Front End JavaScript Development Handbook – React,
              Angular, and Vue Compared</a
            >.
          </li>
          <li>
            Angular, Vue and other frameworks run
            <a href="/leading-narrative">far behind</a>.
          </li>
        </ol>
      </nav>
    `;
  }
}
